<template>
  <div class="table-action">
    <router-link :to="url">
      <CIcon v-c-tooltip="'Editar'" name="cil-pencil" placement="top"> </CIcon>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: ''
    }
  }
}
</script>
