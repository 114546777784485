<template>
  <div id="banners">
    <!-- Content -->
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader
            class="d-flex justify-content-between align-items-center"
          >
            <h4 class="mb-0">Banner Sobre Nós</h4>
          </CCardHeader>
          <CCardBody>
            <!-- Datatable -->
            <CRow>
              <CCol col="12">
                <DataTable
                  :items="datatable.items"
                  :fields="datatable.fields"
                  :enable-export="false"
                  base-route="/banners-about/edit/"
                  @sorterValue="(event) => (query.sorter = event)"
                  @row-clicked="handleRowClick"
                >
                  <template #actions="{ id }">
                    <DatatableEdit :url="`/banners-about/edit/${id}`" />
                    <DatatableRemove
                      @row-clicked="handleRowClick"
                      @deleted="deleteBanner"
                    />
                  </template>
                </DataTable>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import DataTable from '@/components/ui/Datatable'
import DatatableEdit from '@/components/ui/DatatableActions/Edit'
import DatatableRemove from '@/components/ui/DatatableActions/Remove'
import BannerAboutService from '@/services/banner-about.service'

export default {
  metaInfo: {
    title: 'Banners Home',
    titleTemplate: 'Manager - Rodonaves Iveco - %s'
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.state.auth.permissions.includes('manage_banners')) {
        vm.$router.push('/404')
      }
    })
  },

  components: {
    DataTable,
    DatatableEdit,
    DatatableRemove
  },

  data() {
    return {
      datatable: {
        items: [],
        fields: [
          {
            key: 'heading',
            label: 'Banner',
            sorter: true,
            thumb: true
          },
          {
            key: 'actions',
            label: 'Ações',
            sorter: false,
            _style: 'width: 12%;'
          }
        ]
      },
      query: {
        search: '',
        page: 1,
        perpage: localStorage.getItem('perPageItem')
          ? parseFloat(localStorage.getItem('perPageItem'))
          : 10,
        pagination: true,
        status: null,
        situation: null
      },
      pagination: {
        total: 0,
        pages: 0,
        items: 0
      },
      statusOptions: [
        {
          label: 'Ativo',
          value: true
        },
        {
          label: 'Inativo',
          value: false
        }
      ],
      situationOptions: [
        {
          label: 'Todas as situações',
          value: null
        },
        {
          label: 'Expirado',
          value: 'expired'
        },
        {
          label: 'Vigente',
          value: 'current'
        },
        {
          label: 'Agendado',
          value: 'scheduled'
        }
      ],
      rowCliked: {}
    }
  },

  watch: {
    query: {
      handler: 'updateDataTable',
      deep: true
    }
  },

  async created() {
    await this.updateDataTable()
  },

  mounted() {
    localStorage.setItem('bannersAboutOrder', 'updated_at')
  },

  methods: {
    async updateDataTable() {
      const response = await BannerAboutService.getAll(this.query)
      const { data, pagination } = response

      if (data) {
        this.pagination = {
          ...pagination,
          pages: pagination.lastPage,
          items: data.length
        }

        const newItems = response.data.map((item) => {
          return {
            ...item,
            thumb: item.medias.length > 0 ? item.medias[0].url : null
          }
        })

        this.datatable.items = newItems
      }
    },

    handleRowClick(event) {
      this.rowCliked = event
    }
  }
}
</script>

<style lang="scss">
#banners {
  .orderButton {
    margin-right: 10px;
  }

  .thumb {
    width: 80px !important;
  }
}
</style>
