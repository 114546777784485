import axios from './axios.service'

const method = 'banner-abouts'

class BannerAboutService {
  async getAll(queryData) {
    try {
      const params = {
        ...queryData
      }

      const response = await axios.get(`/${method}`, {
        params
      })
      return response.data
    } catch (error) {
      return error
    }
  }

  async getBanner(id) {
    try {
      const response = await axios.get(`/${method}/${id}`)
      return response.data
    } catch (error) {
      return error
    }
  }

  async updateImageTitle(id, name) {
    try {
      const response = await axios({
        method: 'put',
        url: `/media/updatename/${id}`,
        data: {
          name
        }
      })
      return response
    } catch (error) {
      return error
    }
  }

  async updateBanner(id, data) {
    try {
      const response = await axios({
        method: 'put',
        url: `/${method}/${id}`,
        data
      })
      return response
    } catch (error) {
      return error.response
    }
  }
}


export default new BannerAboutService()
