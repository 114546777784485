<template>
  <div class="table-action">
    <CModal
      title="Remover"
      color="danger"
      :show.sync="modal.show"
      :centered="true"
    >
      Ao confirmar essa ação o item não poderá ser recuperado, tem certeza que
      desejar continuar?
      <template #footer>
        <button type="button" class="btn btn-secondary" @click="toggleModal">
          Cancelar
        </button>
        <button type="button" class="btn btn-danger" @click="remove">
          Remover
        </button>
      </template>
    </CModal>
    <a href="javascript:void(0)" @click="toggleModal">
      <CIcon v-c-tooltip="'Remover'" name="cil-trash" placement="top"> </CIcon>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: {
        show: false
      }
    }
  },

  methods: {
    /**
     * Show confirm modal
     * @public
     */
    toggleModal() {
      this.modal.show = !this.modal.show
    },

    /**
     * Send removed item to API
     * @public
     */
    async remove() {
      this.toggleModal()
      this.$emit('deleted')
    }
  }
}
</script>
